import LocomotiveScroll from 'locomotive-scroll';
import observeScrollDirection from './helpers/observeScrollDirection';

const header = document.querySelector('.header');
const headerHeight = header.offsetHeight;
window.isScrollEnabled = true;
let lastHeight = 0;

const initLocomotiveScroll = () => {
	const wrapper = document.querySelector('.wrapper');

	const locoScroll = new LocomotiveScroll({
		el: document.querySelector('[data-scroll-container]'),
		// smooth: true, // remove smooth scrolling
	});

	window.locoScroll = locoScroll;

	const resizeObserver = new ResizeObserver((entries) => {
		if (entries.length > 0) {
			const bodyHeight = entries[0]?.target?.clientHeight;
			if (Math.abs(lastHeight - bodyHeight) > 40) {
				locoScroll.update();
				lastHeight = bodyHeight;
			}
		}
	});

	resizeObserver.observe(document.body);

	document.addEventListener('click', (e) => {
		// Delegate stop scroll clicks
		if (e.target.matches('.js-toggle-scroll')) {
			window.isScrollEnabled ? locoScroll.stop() : locoScroll.start();

			window.isScrollEnabled = !window.isScrollEnabled;
		}

		// Delegate scroll down clicks
		if (e.target.matches('.js-scroll-to')) {
			const target = e.target.getAttribute('data-target');
			const offset = -headerHeight;

			locoScroll.scrollTo(target, offset);
		}
	});

	locoScroll.on('call', (func, dir, obj) => {
		const { el: element } = obj;

		if (dir === 'enter') {
			if (func === 'updateBg') {
				const bg = element.getAttribute('data-bg');
				const color = element.getAttribute('data-color');

				wrapper.style.backgroundColor = bg;
				wrapper.style.color = color;
			}
		}
	});

	locoScroll.on('scroll', (e) => observeScrollDirection(e.scroll.y));

	locoScroll.update();

	/*
    	Toggle class '.is-inview' when element gets in viewport
    	with 150px offset
  	*/
	const toggleClassElements = document.querySelectorAll('.js-toggle-class');

	const callback = (entries) => {
		entries.forEach((entry) => {
			const bcr = entry.boundingClientRect;
			const isBottomVisible = bcr.bottom < window.innerHeight && bcr.bottom;

			if (isBottomVisible) {
				entry.target.classList.add('is-inview');
			}
		});
	};
	let observer = new IntersectionObserver(callback, {
		rootMargin: '-150px',
		threshold: Array(11)
			.fill()
			.map((_, i) => i * 0.1),
	});

	toggleClassElements.forEach((item) => {
		observer.observe(item);
	});
};

export default function animationsUI() {
	document.addEventListener('DOMContentLoaded', () => {
		/* 
			Add timeout to know when exactly will start initializing of locomotive scroll
			after the dom is loaded, so we can adjust intro animation start
		*/
		setTimeout(() => {
			initLocomotiveScroll();
		}, 1000);
	});
}
